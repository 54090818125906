.exception {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 800px;
  margin: 0 auto;
}
.no_authorized_pic {
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg);
  height: 360px;
  width: 430px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  margin-right: 20px;
}
.no_match_pic {
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg");
  height: 360px;
  width: 430px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  margin-right: 20px;
}
.exception_pic {
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg");
  height: 360px;
  width: 430px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  margin-right: 20px;
}
.title {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.text {
  color: rgba(0,0,0,.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 26px;
}