/*reset*/
body {
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
*, :after, :before {
  box-sizing: border-box;
}
canvas {
  display: block
}
/*antd*/
.user_list_form .ant-form-item {
  display: flex;
}
.user_list_form .ant-form-item-control-wrapper {
  flex: 1;
  width: 33.33%;
}
.user_list_form .ant-select-selection-selected-value:hover {
  width: 100%;
  border: 0px solid #ddd;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  cursor: pointer; 
}