.title {
  font-size: 28px;
  text-align: center;
  margin: 50px auto;
}
.info {
  margin: 100px auto;
  width: 800px;
  text-align: center;
  font-size: 24px;
}
.info_title {
  margin-top: 10px;
}
.base {
  font-size: 18px;
}
.card_sum {
  margin: 20px 0;
}
.card {
  text-align: center;
  height: 200px;
  margin-bottom: 30px;
}
.card p:nth-of-type(2) {
  font-size: 15px;
  color: #333;
}
.card p:nth-of-type(1) {
  font-size: 50px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px!important;
}
.contact {
  position: absolute;
  right: 100px;
  top: 85px;
  line-height: 1;
}
.overview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.overview div {
  flex-grow: 1;
}
