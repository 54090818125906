.avatar_section {
  display: flex;
  justify-items: center;
  align-content: center;
  min-width: 100px;
}
.avatar {
  margin-right: 10px;
}
.name {
  letter-spacing: 2px;
  text-align: center
}