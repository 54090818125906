.logo {
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #002140;
}
.img {
  display: block;
}
.menu {
  padding: 16px 0;
}