/*reset*/
body {
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
*, :after, :before {
  box-sizing: border-box;
}
canvas {
  display: block
}
/*antd*/
.user_list_form .ant-form-item {
  display: flex;
}
.user_list_form .ant-form-item-control-wrapper {
  flex: 1 1;
  width: 33.33%;
}
.user_list_form .ant-select-selection-selected-value:hover {
  width: 100%;
  border: 0px solid #ddd;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  cursor: pointer; 
}
.styles_header__1S9dD {
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center
}
.styles_left_section__1QUUi {
  font-size: 20px;
  height: 64px;
  cursor: pointer;
  -webkit-transition: all .3s,padding 0s;
  transition: all .3s,padding 0s;
  padding: 0 24px;
}
.styles_left_section__1QUUi:hover {
  background: rgba(0,0,0,.025);
}
.styles_right_section__1SUIO {
  margin-right: 30px;
}
.styles_avatar_section__2kNRC {
  display: flex;
  justify-items: center;
  align-content: center;
  min-width: 100px;
}
.styles_avatar__2vtrG {
  margin-right: 10px;
}
.styles_name__2zJ-W {
  letter-spacing: 2px;
  text-align: center
}
.styles_footer__ThpYo {
  text-align: center;
}
.styles_logo__3of3L {
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #002140;
}
.styles_img__1c9VB {
  display: block;
}
.styles_menu__2omZb {
  padding: 16px 0;
}
.styles_section__2YhDp {
  width: 100%;
  background: #fff;
  padding: 16px 32px;
}
.styles_title__2K1dM {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  font-weight: 500;
}
.style_title__gD5rr {
  font-size: 28px;
  text-align: center;
  margin: 50px auto;
}
.style_info__vbdd_ {
  margin: 100px auto;
  width: 800px;
  text-align: center;
  font-size: 24px;
}
.style_info_title__1Die9 {
  margin-top: 10px;
}
.style_base__3ofo9 {
  font-size: 18px;
}
.style_card_sum__27LR6 {
  margin: 20px 0;
}
.style_card__R5_ds {
  text-align: center;
  height: 200px;
  margin-bottom: 30px;
}
.style_card__R5_ds p:nth-of-type(2) {
  font-size: 15px;
  color: #333;
}
.style_card__R5_ds p:nth-of-type(1) {
  font-size: 50px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px!important;
}
.style_contact__1zLlg {
  position: absolute;
  right: 100px;
  top: 85px;
  line-height: 1;
}
.style_overview__3hVzk {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.style_overview__3hVzk div {
  flex-grow: 1;
}

.UserLayout_layout__1N5Z- {
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
  background-color: #f0f2f5;
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  min-height: 100vh;
}

.UserLayout_history__2OWFG {
  padding: 10px;
  margin: 10px;
  border: 2px dashed #eee;
}
.Login_login_section__2oON9 {
  padding: 70px 0;
  margin: 0 auto;
  width: 381px;
}
.Login_logo__3vCip {
  display: block;
  margin: 50px auto;
}
.styles_exception__1x-N0 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 800px;
  margin: 0 auto;
}
.styles_no_authorized_pic__36D3J {
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg);
  height: 360px;
  width: 430px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  margin-right: 20px;
}
.styles_no_match_pic__nsijg {
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg");
  height: 360px;
  width: 430px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  margin-right: 20px;
}
.styles_exception_pic__2DiVQ {
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg");
  height: 360px;
  width: 430px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  margin-right: 20px;
}
.styles_title__3e62V {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.styles_text__1wbBY {
  color: rgba(0,0,0,.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 26px;
}
