.layout {
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
  background-color: #f0f2f5;
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  min-height: 100vh;
}

.history {
  padding: 10px;
  margin: 10px;
  border: 2px dashed #eee;
}