.header {
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center
}
.left_section {
  font-size: 20px;
  height: 64px;
  cursor: pointer;
  transition: all .3s,padding 0s;
  padding: 0 24px;
}
.left_section:hover {
  background: rgba(0,0,0,.025);
}
.right_section {
  margin-right: 30px;
}